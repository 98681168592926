.dashboard {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .dashboard-top {
    background: $white;
    border-bottom: 1px solid $borderGray;
    padding: 1.5rem;
    padding-left: 2rem;
    flex: 1;
    max-height: 1.5rem;
    display: flex;
    align-items: center;

    .dashboard-top-left,
    .dashboard-top-right {
      display: flex;
      align-items: center;
      flex: 6;
    }

    .dashboard-top-right {
      justify-content: flex-end;

      p {
        margin: 0px;
        margin-left: 10px;
        font-size: 13px;
        font-weight: bold;
        color: $textGray;
      }
    }

    .dashboard-top-user-info {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }

    .dashboard-top-user-info-dropdown {
      position: absolute;
      z-index: 2000;
      top: 130%;
      right: 0px;
      width: 200px;
      // min-height: 100px;
      background: $white;
      @include raisedShadow;
      border-radius: 5px;

      .dashboard-top-user-info-dropdown-item-br {
        border-bottom: 0.5px solid $hrGray;
      }
      .dashboard-top-user-info-dropdown-item-br,
      .dashboard-top-user-info-dropdown-item {
        padding: 10px;
        display: block;
        margin: 0px;
      }
    }

    .dashboard-top-user-info-touchable {
      display: block;
      display: flex;
      align-items: center;
    }

    .dashboard-title {
      color: $darkGreen;
      font-weight: bold;
      margin: 0rem;
      margin-right: 2rem;
    }
  }
  .dashboard-lower-content {
    display: flex;
    flex: 11;
  }
}

.dashboard-tabs-container {
  flex: 2;
  min-width: 10rem;
  background: $white;
  padding-top: 4rem;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   dispp
  //   flex-direction: column;

  .dashboard-tab {
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-left: 6px solid white;
    p {
      margin: 0px;
      margin-left: 0.5rem;
      color: $textGray;
    }
  }

  .dashboard-tab-active {
    border-left: 6px solid $darkGreen;
    background: $palestGreen;
  }
}

.dashboard-content-container {
  flex: 10;
  max-height: 95vh;
  overflow-y: scroll;
}
