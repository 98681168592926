.map-chart-title {
  h4 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.map-legend-container {
  height: 0.6rem;
  width: 8rem;

  margin-top: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.map-legend-left {
  height: 0.6rem;
  width: 50%;
  border-right: 1px solid $white;
}

#interactiveMap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.map-legend-labels {
  position: absolute;
  left: -10px;
  right: -10px;
  bottom: -17px;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  p {
    font-size: 0.8em;
    font-weight: bold;
    color: $hrGray;
  }
}
