.app-container {
  @include bodyContainer;
}

.full-container {
  @include fullContainer;
}

.app {
  @include noPadding;
  @include noMargin;
}

.fast-spin {
  @include fastSpin;
}

.center-text {
  @include centerText;
}

.center-flex {
  @include centerFlex;
}

.center-flex-row {
  @include centerFlexRow;
}

.reverse-direction {
  transform: scale(-1, -1);
}

.add-shadow {
  @include shadow;
}

// MARGINS
.no-margin {
  @include noMargin;
}

.small-margin {
  margin: 0.5rem;
}

.small-margin-bottom {
  margin-bottom: 1.5rem;
}

// BACKGROUNDS
.App {
  background: $backgroundGray;
}

// FONTS
.title1 {
  font-size: 1.8em;
}

.light-font {
  font-weight: 100;
}

.gray-font {
  color: $textGray;
}

.small-text {
  font-size: 0.9em;
}

.smaller-text {
  font-size: 0.7em;
}

.warning-text {
  color: $actionRed;
  font-size: 0.8em;
}

// FORMS
.form-warning {
  text-align: left;
}

//
.form-group-div {
  margin-bottom: 20px;
  .ant-select {
    text-align: left;
  }

  // .ant-checkbox-wrapper span:nth-child(2) {
  //   width: 100% !important;
  //   border: 2px solid red;
  // }
}

.form-button {
  // background: $comasAfricaBrown;
  margin-left: 2px;
  width: 200px;
  padding: 6px;
  @include raisedShadow;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  /* hide it on smaller screen */
  .hide-on-small-screens {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  /* hide it on larger screens */
  .hide-on-large-screens {
    display: none !important;
  }
}
