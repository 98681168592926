.logout-modal-no {
  background-color: $darkGreen;
  border-color: $darkGreen;
}

.logout-modal-no:hover {
  background-color: $lightGreen;
  border-color: $darkGreen;
}

.logout-modal-yes:hover {
  border-color: $darkGreen;
  color: $darkGreen;
}
