.custom-button {
  padding: 0.5rem;
  border-radius: 3px;
  border: 0.5px solid $borderGray;
  display: flex;
  align-items: center;
  position: relative;
}

.custom-loading-spinner {
  position: absolute;
  right: 10px;
  top: auto;
  bottom: auto;
  @include fastSpin;
}
