.dp-popup {
  background: $white;
  border-radius: 15px;
  min-height: 20rem;
  min-width: 50rem;

  .ant-select-dropdown,
  .ant-select-selection-item {
    // border: 2px solid red !important;
    position: relative;
    z-index: 1500;
  }

  .ant-select-selection-item {
    // border: 2px solid red !important;
    position: relative;
    z-index: 1500;
  }
}

.dp-popup-top {
  border-bottom: 1px solid $inputBorder;
  display: flex;
  flex-direction: row;
  position: relative;
  .dp-popup-top-title {
    flex: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 0px;
      color: $hrGray;
      font-weight: bold;
    }
  }
  .dp-popup-top-icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dp-progress {
  position: absolute;
  top: 80%;
  left: 32px;
  right: 32px;
  height: 20px;
}

.dp-popup-top {
  padding: 1rem 2rem;
}

.dp-popup-bottom {
  padding: 2rem;
}

.dp-popup-bottom {
  display: flex;
}

.dp-popup-bottom-left {
  flex: 6;
}

.dp-popup-bottom-right {
  flex: 6;
}

.auto-dispatch-text {
  font-size: 1em;
  font-weight: bold;
  color: $textGray;
  margin: 1.2rem 0px 0.5em 0px;
}

.dp-popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  button {
    min-width: 40%;
  }
}

.dp-white-btn {
  border-color: $hrGray;
}

.dp-input-container {
  position: relative;
  z-index: 1600;
  margin-top: 1rem;

  .custom-input-container,
  .custom-input-container-active {
    background: $white !important;
  }

  .dp-input-container-select .ant-select-selector {
    border-color: $inputBorder;
    // padding: 20px;
    border-radius: 3px;
  }
}

.dp-popup-map {
  margin-left: 2rem;
  width: 22rem;
  height: 22rem;
  overflow: hidden;
  position: relative;
  img {
    width: 26rem;
  }
  .dp-map-input-container {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
  }
}

.dp-adornment-container {
  // height: 20px !important;
}
