$textGray: #808483;
$actionRed: #ef3123;
$inputGray: #f5f9f7;
$disabledGray: #d5d8dd;
$borderGray: #e0e0e0;
$hrGray: #979797;
$white: #ffffff;
$black: #000000;
$seeThroughBlack: #00000033;
$borderGray: #e2e1e1;
$yellow: #ffdd03;
$darkGreen: #006c3a;
$lightGreen: #039752;
$backgroundGray: #f5f5f7;
$hoverGreen: #f5f9f8;
$darkMaroon: #760e1a;
$blue: #458de1;
$darkBackground: #2f2f2f;
$palestGreen: #f2f8f6;
$darkText: #666666;
$inputBorder: #c4c4c4;
$statCardGreen: #e5f0eb;
$statPurple: #a877d4;
$statCardPurple: #f9efff;
$statCardBlue: #eaf9ff;
$weyonLightBlue: #4ed6ff;
$paleRed: #ef31231a;
$gold: #f2d400;
