.dps {
  padding: 2rem;
}

.dps-top {
  padding-left: 1rem;
  padding-right: 1rem;
  h3 {
    color: $textGray;
    margin: 0rem;
    font-weight: bold;
  }
}

// BOTTOM
.dps-bottom {
  border-radius: 10px;
  background: $white;
  padding: 1rem;

  margin-top: 1.5rem;
}

.dps-select-row {
  display: flex;
}

.dps-select-container {
  min-width: 12rem;
  margin-right: 1.5rem;

  .dps-select-label {
    font-weight: bold;
    color: $textGray;
    padding-left: 0.5rem;
    margin-bottom: 0.3rem;
  }
}

.dps-filter-button-text {
  margin: 0rem;
  margin-left: 0.5rem;
  color: $black;
}

.dps-filter-button {
  margin-left: 0rem;
  .custom-button {
    padding: 0.3rem 0.8rem 0.3rem 0.3rem;
  }
}

.dps-tabs {
  display: flex;
  // padding-left: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .dps-tab {
    border-bottom: 1px solid $textGray;
    color: $textGray;
    font-weight: bold;
    // font-size: 0.9em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    cursor: pointer;
    min-width: 8rem;
    text-align: center;
  }
  .dps-tab-active {
    border-bottom: 1px solid $darkGreen;
    color: $darkGreen;
  }
}

.dps-search-row {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}

.dps-search-container {
  display: flex;
  background: $white;
  @include raisedShadow;
  padding: 0.2rem;
  border-radius: 3px;
  align-items: center;
  width: 25rem;
  margin-left: 2rem;
  height: 35px;
}

.dps-search-input-wrapper {
  flex: 10;

  input {
    border: none;
    width: 100%;
    &:focus {
      border: none;
      //   background: $inputGray;
      outline: none;
    }
  }
}

.dps-search-icon {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dps-table {
  th {
    background: $white !important;
  }
  b {
    color: $textGray;
    text-transform: uppercase;
  }
}

.dps-table-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8rem;
  .dps-table-end-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dps-export-row {
  display: flex;
  align-items: center;
}
