.voucher-list-btn {
  position: relative;
}

.voucher-list-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  @include raisedShadow;
  z-index: inherit;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $actionRed;
  font-weight: bold;
}
