.jobs {
  padding: 2rem;
}

.jobs-top {
  padding-left: 1rem;
  padding-right: 1rem;
  h3 {
    color: $textGray;
    margin: 0rem;
    font-weight: bold;
  }
}

// BOTTOM
.jobs-bottom {
  border-radius: 10px;
  background: $white;
  padding: 1rem;

  margin-top: 1.5rem;
}

.jobs-select-row {
  display: flex;
}

.jobs-select-container {
  min-width: 12rem;
  margin-right: 1.5rem;

  .jobs-select-label {
    font-weight: bold;
    color: $textGray;
    padding-left: 0.5rem;
    margin-bottom: 0.3rem;
  }
}

.jobs-filter-button-text {
  margin: 0rem;
  margin-left: 0.5rem;
  color: $black;
}

.jobs-filter-button {
  margin-left: 0rem;
  .custom-button {
    padding: 0.3rem 0.8rem 0.3rem 0.3rem;
  }
}

.jobs-tabs {
  display: flex;
  // padding-left: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .jobs-tab {
    border-bottom: 1px solid $textGray;
    color: $textGray;
    font-weight: bold;
    // font-size: 0.9em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    cursor: pointer;
    min-width: 8rem;
    text-align: center;
  }
  .jobs-tab-active {
    border-bottom: 1px solid $darkGreen;
    color: $darkGreen;
  }
}

.jobs-search-row {
  display: flex;
  margin-bottom: 1.5rem;
}

.job-search-container {
  display: flex;
  background: $white;
  @include raisedShadow;
  padding: 0.2rem;
  border-radius: 3px;
  align-items: center;
  width: 25rem;
  margin-left: 2rem;
  max-height: 2.5em;
}

.job-search-input-wrapper {
  flex: 10;
  input {
    border: none;
    width: 100%;
    &:focus {
      border: none;
      //   background: $inputGray;
      outline: none;
    }
  }
}

.job-search-icon {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobs-table {
  th {
    background: $white !important;
  }
  b {
    color: $textGray;
    text-transform: uppercase;
  }

  overflow-x: scroll;
}

.warning-row {
  background: $paleRed;
}
