.dashboard-vouchers {
  padding: 2rem;
  max-height: 100vh;
  overflow-y: scroll;
}

.dashboard-vouchers-top {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;

  // justify-content: space-between;
}

.dashboard-vouchers-top-date {
  padding: 0.5rem;
  border-radius: 4px;
  border: 0.5px solid $borderGray;
  display: flex;
  align-items: center;
  max-width: 15rem;
  background: $white;
  div {
    display: flex;
    align-items: center;
  }

  .dashboard-vouchers-top-date-text {
    font-size: 0.9em;
  }
}

.dashboard-vouchers-top-date-icon {
  margin-right: 0.5rem;
}

.dashboard-vouchers-tabs {
  display: flex;
  padding-left: 3rem;
  p {
    margin-bottom: 0px !important;
  }
  .dashboard-vouchers-tab {
    // border-bottom: 2px solid $textGray;
    color: $textGray;
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    min-width: 8rem;
    text-align: center;
  }
  .dashboard-vouchers-tab-active {
    border-bottom: 2px solid $darkGreen;
    color: $darkGreen;
  }
}

.dashboard-vouchers-dispatch-button-text {
  margin: 0rem;
  margin-left: 0.5rem;
  color: $black;
}

.dashboard-vouchers-dispatch-button {
  margin-left: 6rem;
  //   flex: 6;
}

// OVERVIEW
.vouchers-chart-container {
  background: $white;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;

  overflow-x: scroll;

  .vouchers-chart-container-left {
    flex: 6;
    border-right: 2px solid $hrGray;
    max-height: 30rem;
    overflow-y: scroll;
  }
  .vouchers-chart-container-right {
    flex: 6;
    max-height: 30rem;
    overflow-y: scroll;
  }
}

.vc-c-label-custom {
  font-size: 0.9em;
  div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    p {
      margin: 0px;
      margin-left: 5px;
      margin-bottom: -5px;
      font-size: 1.4em;
      font-weight: 600 !important;
    }
  }
}

// SETTINGS
.voucher-settings-card {
  margin-top: 20px;
  background: $white;
  padding: 20px;
  border-radius: 10px;
}

.voucher-settings-top-top {
  border-bottom: 1px solid $inputBorder;
  padding-bottom: 30px;
  display: flex;
}

.bleach {
  color: $white;
}

.voucher-settings-top-bottom {
  margin-top: 10px;
}

.voucher-settings-top-bottom-title {
  display: flex;

  h2 {
    font-weight: bold !important;
    margin: 0px;
    margin-left: 5px;
    margin-bottom: -5px;
  }
  align-items: center;
}

.voucher-settings-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .voucher-chart-label {
    min-width: 200px;
    margin-right: 10px;
  }
}

// Misc
.vouchers-table {
  overflow-x: scroll;
  // overflow: hidden;
  max-width: 75vw;
}

.vouchers-search-row {
  display: flex;
  margin-bottom: 1.5rem;
}

.voucher-search-container {
  display: flex;
  background: $white;
  @include raisedShadow;
  padding: 0.2rem;
  border-radius: 3px;
  align-items: center;
  width: 25rem;
  margin-bottom: 2rem;
  // margin-left: 2rem;
}

.voucher-search-input-wrapper {
  flex: 10;
  input {
    border: none;
    width: 100%;
    &:focus {
      border: none;
      //   background: $inputGray;
      outline: none;
    }
  }
}

.voucher-search-icon {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucher-spinner {
  @include fastSpin;
}

.voucher-spinner-btn {
  padding: 0.3rem;
}
