.map-job-marker {
  //   border: 1px solid green;
  min-width: 50px;
  //   background: $seeThroughBlack;
  border-radius: 8px;
  padding-bottom: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    margin: 0px;
  }
  p {
    margin: 0rem !important;
    text-align: center;
    font-weight: bold;
    min-width: 30px;
  }
}
