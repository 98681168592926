.job-card {
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: flex;
  border-bottom: 0.5px solid $borderGray;
  border-radius: 10px;
}

.job-card-left {
  flex: 11;
}

.job-card-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-card-pair {
  display: flex;
  margin-bottom: 0.3rem;
}

.job-modal {
  max-height: 25rem;
  overflow-y: scroll;
  z-index: 5000;
  .job-card-pair {
    display: flex;
    margin-bottom: 0.5rem;
    .job-card-key {
      flex: 5;
    }

    .job-card-value {
      flex: 7;
    }
  }
}

.job-card-key {
  color: $textGray;
  flex: 6;
}

.job-card-value {
  color: $darkText;
  flex: 6;
}
