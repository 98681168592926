.dashboard-home {
  padding: 2rem;
  max-height: 100vh;
  overflow-y: scroll;
}

.dashboard-home-top {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  // justify-content: space-between;
}

.dashboard-home-top-date {
  padding: 0.5rem;
  border-radius: 4px;
  border: 0.5px solid $borderGray;
  display: flex;
  align-items: center;
  max-width: 15rem;
  background: $white;
  div {
    display: flex;
    align-items: center;
  }

  .dashboard-home-top-date-text {
    font-size: 0.9em;
  }
}

.dashboard-home-top-date-icon {
  margin-right: 0.5rem;
}

.dashboard-home-tabs {
  display: flex;
  padding-left: 3rem;
  .dashboard-home-tab {
    border-bottom: 2px solid $textGray;
    color: $textGray;
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    width: 8rem;
    text-align: center;
  }
  .dashboard-home-tab-active {
    border-bottom: 2px solid $darkGreen;
    color: $darkGreen;
  }
}

.dashboard-home-dispatch-button-text {
  margin: 0rem;
  margin-left: 0.5rem;
  color: $black;
}

.dashboard-home-dispatch-button {
  margin-left: 3rem;
}

// BOTTOM
.dashboard-home-bottom {
  border-radius: 10px;
  background: $white;
  padding: 1rem;
  display: flex;
  margin-top: 1.5rem;

  .dashboard-home-bottom-left {
    flex: 9;
    border-right: 1px solid $borderGray;
  }
  .dashboard-home-bottom-left-alt {
    flex: 6;
    border-right: 1px solid $borderGray;
    // max-width: 50%;
  }
  .dashboard-home-bottom-right {
    flex: 3;
    padding-left: 2rem;

    .default-map-container {
      .default-map {
        width: 90%;
      }
    }
  }
  .dashboard-home-bottom-right-alt {
    flex: 6;
    // min-width: 50%;
    // padding-left: 2rem;
  }

  .dashboard-home-bottom-left-title {
    @include raisedShadow;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    p {
      margin: 0rem;
      color: $white;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.9em;
    }

    .dashboard-home-bottom-left-title-dropdown {
      position: absolute;
      right: 0rem;
      top: 120%;

      @include raisedShadow;
      background: $white;
      padding: 0.8rem;
      border-radius: 5px;

      .lt-dropdown-item {
        margin-top: 0.5rem;
        cursor: pointer;
        margin-bottom: 0.5rem;
        font-size: 0.8em;
        &:hover {
          color: $darkGreen;
        }
      }
    }
  }

  .dashboard-home-bottom-left-jobs-list {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 0.5rem;
  }
}

.dashboard-home-stat-card {
  //   background: $statCardGreen;
  border-radius: 15px;
  padding: 0.6rem 2rem 0.6rem 2rem;
  margin-bottom: 1rem;

  text-align: right;
  .dashboard-home-stat-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0px;
    }
  }
  .dashboard-home-stat-card-title {
    color: $textGray;
    text-transform: capitalize;
  }
  .dashboard-home-stat-card-stat {
    color: $black;
    font-size: 1.4em;
    font-weight: bold;
  }
  .dashboard-home-stat-card-stat-unit {
    color: $hrGray;
    font-size: 0.9em;
    padding-left: 0.5rem;
    sup {
      font-size: 1.2em;
    }
  }
}

.dashboard-home-select-row {
  display: flex;
}

.dashboard-home-select-container {
  min-width: 12rem;
  margin-right: 1.5rem;
  .ant-select-selector {
    height: 0px !important;
    border: 0px !important;
    background: 0px !important;
    opacity: 0;
  }

  .ant-select-arrow {
    display: none;
  }
  .dashboard-home-select-label {
    font-weight: bold;
    color: $textGray;
    padding-left: 0.5rem;
    margin-bottom: 0.3rem;
  }
}
