// SHADOWS
@mixin shadow {
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
}

@mixin minShadow {
  box-shadow: 0px 1px 3px 0px rgba(178, 178, 179, 0.28);
}

@mixin contentBoxBorder {
  border: solid 1px rgba(46, 91, 255, 0.08);
}

@mixin deepShadow {
  // box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;
  box-shadow: 0px 8px 2px 2px rgba(32, 33, 36, 0.2);
}

@mixin nearDeepShadow {
  // box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;
  box-shadow: 2px 2px 3px 2px rgba(32, 33, 36, 0.3);
}

@mixin raisedShadow {
  box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
    0 1px 3px 1px rgba(65, 69, 73, 0.15);
}

// SPIN ANIMATIONS
@mixin slowSpin {
  animation: spinner infinite 20s linear;
}

@mixin fastSpin {
  animation: spinner infinite 2s linear;
}

@mixin mediumSpin {
  animation: spinner infinite 5s linear;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// CONTAINERS
@mixin bodyContainer {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin fullContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerFlexRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

@mixin noPadding {
  padding: 0rem;
}

@mixin noMargin {
  margin: 0px;
}

// TEXT
@mixin centerText {
  text-align: center;
}

@mixin inputTitle {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  color: $textGray;
}
