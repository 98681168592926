.programName {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.programDropdownContainer {
  position: relative;
}

.programDropdown {
  background: #ffffff;
  position: absolute;
  width: 15rem;
  top: 100%;
  left: 0px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
    0 1px 3px 1px rgba(65, 69, 73, 0.15);
}

.programOption {
  padding: 10px;
  margin: none;
}

.programOption:hover {
  background: #d9e5f0;
  cursor: pointer;
}
