.circle-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 0px 20px;
}
.circle-label-circle {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  overflow: hidden;
}

.circle-label-label {
  text-transform: uppercase;
  margin: 0px;
  margin-left: 5px;
  font-weight: bold;
  color: $textGray;
  font-size: 1.1em;
}
