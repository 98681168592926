.voucher-chart-container {
  padding: 2rem;
  max-width: 600px;
}

.voucher-chart-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  h2 {
    margin: 0px;
    margin-left: 20px;
    font-weight: 600 !important;
  }
}

.voucher-chart-flex {
  display: flex;
  flex-direction: row;
}

.voucher-chart-right,
.voucher-chart-left {
  flex: 6;
}

.voucher-chart-left {
}

.voucher-chart-left-canvas-container {
  //   border: 2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voucher-chart-label {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;
  max-width: 250px;
  margin-bottom: 10px;
}

.voucher-chart-label-left {
  flex: 2;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0px;
    font-size: 1.6em !important;
    color: $white;
    font-weight: 500 !important;
  }
}

.voucher-chart-label-right {
  flex: 10;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: flex-end;
}
