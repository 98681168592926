.checkbox-container {
  display: flex;
  align-items: center;
}
.checkbox-checkbox {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.checkbox-label {
  flex: 10.5;
}

.checkbox-label-active {
  flex: 10.5;
}
