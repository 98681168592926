.image-container {
  @include raisedShadow; /* box-shadow: 1px 1.5px 3px #929292; */
  // animation: 1s ease 0s 1 slideInFromBottom;
  transition: all 0.5s ease;

  display: inline-block;
  min-height: 12rem !important;
  min-width: 12rem !important;

  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: $darkBackground;
  position: relative;
}

/* SHAPES */
/* Common values for all shapes */
.square-upload-container,
.rectangle-upload-container,
.circle-upload-container {
  overflow: hidden;
}

.square-upload-container,
.circle-upload-container {
  height: 12rem;
  width: 12rem;
}

.square-upload-container {
  background-size: contain !important;
}

.rectangle-upload-container {
  background-size: contain !important;
  height: 12rem;
  width: 100%;
}

.circle-upload-container {
  border-radius: 50%;
  background-size: cover !important;
}

.image-container:hover {
  color: rgb(97, 96, 96);
  /* box-shadow: 1px 1.5px 3px #747373; */
}

.image-container:focus,
.image-container:active {
  color: #464646;
  /* box-shadow: 1px 1.5px 3px #5c5b5b; */
}

/* Image preview container*/
.image-preview {
  position: relative;
}

/* Default text */
.image-upload-default-text {
  //   font-weight: 600;
  font-size: 0.95em;
}

/* Input tag */
.image-upload-input {
  display: none;
}

/* Upload Icon */
.image-upload-icon-container {
  font-size: 3em;
}

/* Cancel Image Button */
.cancel-image-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.4em;
  color: rgba(240, 47, 40, 0.9);
}
.cancel-image-btn:hover {
  color: #c0211c;
}
.cancel-image-btn:active,
.cancel-image-btn:focus {
  color: #941a14;
}

/* Shapes */
