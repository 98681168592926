.single-voucher-container {
  padding: 2rem;
  max-height: 100vh;
  overflow-y: scroll;
}

.single-vouchers-top {
  display: flex;
  flex-direction: row;
  font-size: 1.3em;
  align-items: center;
  h3 {
    margin: 0px;
    margin-right: 7px;
  }

  .single-vouchers-left,
  .single-vouchers-right {
    flex: 6;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .single-vouchers-right {
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
}

.single-vouchers-bottom {
  display: flex;
  align-items: stretch;
  padding-top: 30px;
}

.single-vouchers-bottom-right,
.single-vouchers-bottom-left {
  background: $white;
  border-radius: 20px;
  min-height: 15rem;
  padding: 20px;
  display: flex;
  align-items: stretch;
}

.single-vouchers-bottom-left {
  flex: 8;
  margin-right: 20px;
  display: flex;
}

.single-vouchers-bottom-right {
  flex: 4;
}

.sv-detail-container {
  margin-bottom: 20px;
  p {
    margin: 0px;
  }
}

.sv-bottom-title {
  text-transform: uppercase;
  font-weight: bold;
  color: $darkText;
  margin: 0px;
}

.sv-bottom-detail-pair {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
}

.sv-bottom-detail-left {
  color: $textGray;
  flex: 4;
}

.sv-bottom-detail-right {
  margin-left: 10px !important;
  color: $black;
  flex: 8;
}

.sv-bottom-col {
  min-width: 30%;
  padding-right: 35px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
