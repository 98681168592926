.app-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  // @include centerFlex;
  display: flex;
  padding: 4rem;
  justify-content: center;
  // flex-grow: 1;
  align-items: center;
}
// .modal-content {
//   // margin-left: auto;
//   // margin-right: auto;
// }

@media screen and (min-width: 0px) and (max-width: 768px) {
  .app-modal {
    padding: 4rem 2rem;
  }
}
