.add-sp-modal-container {
  min-width: 50rem;
}
.add-sp-modal {
  display: flex;
  justify-content: space-between;
}

.add-sp-modal-left,
.add-sp-modal-right {
  flex: 5.5;
  max-width: 45%;
}
