.add-vp-container {
  min-width: 45rem;
}

.add-vp-fields {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.add-vp-key {
  flex: 3;
  color: $darkText;
  margin: 0rem;
}

.add-vp-val {
  flex: 9;
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
  .checkbox-container {
    margin-right: 10px;
    .checkbox-label {
      margin-left: 5px;
    }
  }
}
