.ad-container {
  position: relative;
}

.ad-dropdown {
  position: absolute;
  z-index: 2000;
  top: 130%;
  right: 0px;
  width: 120px;
  // min-height: 100px;
  background: $white;
  @include raisedShadow;
  border-radius: 5px;

  .ad-dropdown-item-br,
  .ad-dropdown-item {
    padding: 10px;
    display: block;
    margin: 0px;
    &:hover {
      background: $palestGreen;
    }
  }
}
