.bottom-bars-labels {
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.bottom-overview-container {
  display: flex;
  .bottom-overview-left {
    flex: 8;
  }
  .bottom-overview-right {
    flex: 4;
  }
}
