.dashboard-chart {
  padding: 20px;
  h3 {
    font-weight: bold;
    text-align: left;
  }
}
.dashboard-chart-container {
  max-width: 100%;
  //   border: 2px solid red;
  //   color:rgb(248, 105, 3);
  overflow-x: scroll;
}

.dashboard-chart-wrapper {
  width: 50vw;
  height: 380px;
  padding: 0.5rem;
  //   height: 600px;
  canvas {
    width: 50vw;
    background: $white;
    // @include raisedShadow;
  }
}

.dashboard-chart-wrapper-small {
  width: 15vw;
  height: 380px;

  padding: 0.5rem;
  canvas {
    width: 15vw;
    background: $white;
    height: 100%;
    // min-height: 360px;
    // @include raisedShadow;
  }
}

.dashboard-chart-icons {
  display: flex;
  padding-left: 10px;
  padding-bottom: 15px;
  .dashboard-chart-icon {
    @include raisedShadow;
    background: $white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dashboard-chart {
  h3 {
    font-weight: 400;
    font-size: 1.1em;
    text-align: center;
  }
}
