// FONT RESET
@font-face {
  font-family: "Rubik";
  src: url("./../../assets/fonts/Rubik/Rubik-VariableFont_wght.ttf")
    format("truetype");
}

// FONT RESET
@font-face {
  font-family: "Avenir";
  src: url("./../../assets/fonts/avenir_ff/Avenir-Medium.otf");
  font-style: initial;
}
@font-face {
  font-family: "Avenir";
  src: url("./../../assets/fonts/avenir_ff/AvenirLTStd-Black.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Avenir";
  src: url("./../../assets/fonts/avenir_ff/Avenir-Light.otf");
  font-weight: lighter;
}

body {
  font-family: "Avenir", Arial, sans-serif;
  font-weight: 300;
}

// select {
//   padding: 0.6rem;
//   // border-radius: 5px;
//   border: none;
//   margin-top: 0.5rem;
//   border: 1px solid $borderGray;
//   background: $inputGray;
// }

input,
.ant-input {
  background: $white !important;
  background-color: $white !important;
}

input:-internal-autofill-selected {
  background: $white !important;
  background-color: $white !important;
}

textarea:focus,
select:focus {
  outline: none;
  border: 1px solid $actionRed;
}
button:focus {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0rem;
  margin: 0rem;
}
