.container {
  /* position: relative; */
  max-height: 25rem;
  overflow-y: scroll;
  /* z-index: 500000; */
}

.modal {
  /* z-index: 500000; */
}

.btnContainer {
  width: auto;
  width: 10rem;
}
