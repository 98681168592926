.login-page {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18rem;

  form {
    margin-top: 1.5rem;
    .custom-input-container {
      width: 18rem;
      margin-bottom: 1rem;
    }
    button {
      width: 18rem;
      span {
        width: 18rem;
      }
    }
  }
}

.login-logo {
  width: 200px;
}

.login-clickable-text {
  color: $darkGreen;
  text-decoration: underline;
  font-size: 0.9em;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.login-forgot-password-title,
.login-forgot-password-instruction {
  color: $textGray;
}
