.live-tracking {
  padding: 2rem;
}

.live-tracking-top {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
}

.live-tracking-top-date {
  padding: 0.5rem;
  border-radius: 4px;
  border: 0.5px solid $borderGray;
  display: flex;
  align-items: center;
  max-width: 15rem;
  background: $white;
  div {
    display: flex;
    align-items: center;
  }

  .live-tracking-top-date-text {
    font-size: 0.9em;
  }
}

.live-tracking-top-date-icon {
  margin-right: 0.5rem;
}

.live-tracking-tabs {
  display: flex;
  padding-left: 3rem;
  .live-tracking-tab {
    border-bottom: 2px solid $textGray;
    color: $textGray;
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    width: 8rem;
    text-align: left;
  }
  .live-tracking-tab-active {
    border-bottom: 2px solid $darkGreen;
    color: $darkGreen;
  }
}

.live-tracking-dispatch-button-text {
  margin: 0rem;
  margin-left: 0.5rem;
  color: $white;
}

.live-tracking-dispatch-button {
  margin-left: 2rem;
}

// BOTTOM
.live-tracking-bottom {
  border-radius: 10px;
  background: $white;
  padding: 1rem;
  display: flex;
  margin-top: 1.5rem;

  .live-tracking-bottom-left {
    flex: 5;
  }
  .live-tracking-bottom-right {
    flex: 7;
    padding-left: 0.8rem;

    .default-map-container {
      width: 500px;
      height: 500px;
      overflow: hidden;
      .default-map {
        width: 90%;
      }
    }
  }

  .live-tracking-bottom-left-title {
    @include raisedShadow;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    p {
      margin: 0rem;
      color: $white;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.9em;
    }

    .live-tracking-bottom-left-title-dropdown {
      position: absolute;
      right: 0rem;
      top: 120%;

      @include raisedShadow;
      background: $white;
      padding: 0.8rem;
      border-radius: 5px;

      .lt-dropdown-item {
        margin-top: 0.5rem;
        cursor: pointer;
        margin-bottom: 0.5rem;
        font-size: 0.8em;
        &:hover {
          color: $darkGreen;
        }
      }
    }
  }

  .live-tracking-bottom-left-jobs-list {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 0.5rem;
  }
}

.live-tracking-no-results-container {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.live-tracking-no-results-img {
  width: 12rem;
  margin-bottom: 2rem;
}

.live-tracking-no-results-text {
  font-size: 1em;
  color: $textGray;
  text-align: center;
}

.live-tracking-spinner {
  margin-top: 10px;
  @include fastSpin;
}
