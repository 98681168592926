.container {
  display: flex;
  background: #ffffff;
  align-items: center;
  padding: 5px;
  padding-right: 8px;
  border: 1px solid #d6d6da;
  border-radius: 5px;
}

.container input {
  background: #ffffff !important;
  border: none;
}

.iconWrapper {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textContainer {
  position: relative;
  z-index: 3000;
}

.textContainer p {
  margin: 0px;
  cursor: pointer;
  white-space: nowrap;
}

.calendarContainer {
  position: absolute;
  top: 140%;
}

.calendarContainer .rmdp-today .sd {
  background-color: red !important ;
}

.confirmBtn {
  border: none;
  margin: 10px;
  cursor: pointer;
  padding: 3px;
  /* border-radius: 5px; */
  min-width: 80px;
  /* font-weight: bold; */
}
