.custom-input-container {
  border: 1px solid $inputBorder;
  &:hover {
    border: 1px solid $darkGreen;
    // color: $darkGreen !important;
  }
}

.custom-input-container-active {
  border: 1px solid $darkGreen;
}

.custom-input-container,
.custom-input-container-active {
  display: flex;
  background: $inputGray;

  padding: 8px;
  border-radius: 5px;
  &:focus {
    border: 1px solid $darkGreen;
  }

  .custom-input {
    background-color: rgba($color: #000000, $alpha: 0) !important;
  }
  input {
    border: none;

    width: 100%;

    &:focus {
      border: none;
      //   background: $inputGray;
      outline: none;
    }
  }
}

.custom-input-icon-left {
  flex: 1.5;
}

.custom-input-input-wrapper {
  flex: 10.5;
  //   border: 2px solid red;
}

.custom-input-icon-right {
  flex: 1.5;
}
